import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Input,
  Modal,
  Paper,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { ToastContainer, toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import { BASE_URL } from "../../../global";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { showComponent } from "../../helper/helpers";
import MultiValueInputModal from "../../common/multiValueInputModal";
import { createDucMasterGuide } from "./guide";
import { allSettings } from "../../../utils/components/allSettings";
import axiosWithToken from "../../../utils/components/axiosTokenConfig";
import ErrorModal from "../../../utils/components/errorModal";
import EditWrap from "../../commonComponent/editWrap";

const calArray = [
  { id: 1, label: "3 Months" },
  { id: 2, label: "6 Months" },
  { id: 3, label: "12 Months" },
  { id: 3, label: "18 Months" },
  { id: 4, label: "24 Months" },
];

const modalConfigs = [
  {
    buttonLabel: "Range",
    transformedStringLabel: "ranges",
  },
  {
    buttonLabel: "Least Count",
    transformedStringLabel: "lc",
  },
  {
    buttonLabel: "Operating Range",
    transformedStringLabel: "operatingRange",
  },
  {
    buttonLabel: "Accuracy",
    transformedStringLabel: "accuracy",
  },
];

const EditDucMaster = () => {
  const createducMasterGuides = createDucMasterGuide();
  const [inputStates, setInputStates] = React.useState({});
  const [clientList, setClientList] = React.useState([]);
  const [instrumentstList, setInstrumentstList] = React.useState([]);
  const [additionalColumns, setAdditionalColumns] = React.useState([]);
  const [extraColumns, setExtraColumns] = React.useState({});
  const [errormodalIsOpen, setErrorModalIsOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState(null);
  const userID = localStorage.getItem("id");
  const [disciplineId, setDisciplineId] = React.useState(null);
  const [editAccess, setEditAccess] = React.useState(localStorage.getItem("editAccess")?.split(",").map(Number));

  const openErrorModal = (errorMessage, stackTrace, fileName) => {
    setErrorMessage({ errorMessage, stackTrace, fileName });
    setErrorModalIsOpen(true);
  };
  const closeErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  const params = useParams();
  function extractUnit(value) {
    const groups = value?.split("||");
    const unit = groups?.map((group) => {
      const parts = group?.split("|");
      if (parts?.length === 1 || parts?.length === 2) {
        return parts[parts?.length - 1]?.split("#")[1];
      }
      return null;
    })?.find((unit) => unit !== null);

    return unit ? `#${unit}` : "";
  }
  const updateInputObject = (key, value, q) => {
    let newInputObject = {
      ...inputStates,
    };
    if (key === 'ranges') {
      const unit = extractUnit(value);
      newInputObject.accuracy = `${unit}`;
      newInputObject.operatingRange = `${unit}`;
      newInputObject.lc = `${unit}`;
    }
    newInputObject[key] = value;
    setInputStates({ ...newInputObject });
  };

  const handleInputChange = (columnName, value) => {
    setExtraColumns((prevState) => ({
      ...prevState,
      [columnName]: value,
    }));
  };

  const handleSubmit = () => {
    let url = BASE_URL;

    const payload = {
      ...inputStates,
      extraColumns: JSON.stringify(extraColumns),
       CreatedBy:userID,
    };

    if (params.id) {
      axiosWithToken
        .patch(url + `ducMaster/${params.id}`, payload)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("ducMaster  edit successfully !");
        })
        .catch((err) => {
          if (err.message !== "request_aborted") {
            toast.error(
              <h6
                onClick={() =>
                  openErrorModal(
                    err.message,
                    "handleSubmit",
                    "Edit DucMasterList"
                  )
                }
              >
                <u>Error: {err.message}</u>
              </h6>
            );
          }
        });
    } else {
      axiosWithToken
        .post(url + "ducMaster", payload)
        .then((res) => {
          setTimeout(refresh, 500);
          toast("ducMaster  created successfully !");
        })
        .catch((err) => {
          if (err.message !== "request_aborted") {
            toast.error(
              <h6
                onClick={() =>
                  openErrorModal(
                    err.message,
                    "handleSubmit",
                    "Edit DucMasterList"
                  )
                }
              >
                <u>Error: {err.message}</u>
              </h6>
            );
          }
        });
    }
  };

  const fetchDucMaster = () => {
    let url = BASE_URL;
    axiosWithToken
      .get(url + `ducMaster/${params.id}`)
      .then((res) => {
        let dataObject = res.data[0];
        let {
          lastModified,
          extraColumns: extraColumnsStr,
          ...restDataObject
        } = dataObject;

        let newData = {
          ...inputStates,
          ...restDataObject,
        };
        dataObject && setInputStates(newData);

        let parsedExtraColumns = null;
        try {
          parsedExtraColumns = extraColumnsStr
            ? JSON.parse(extraColumnsStr)
            : null;
        } catch (error) {
          console.error("Error parsing extra columns JSON:", error);
        }

        if (parsedExtraColumns) {
          setExtraColumns((prevState) => ({
            ...prevState,
            ...parsedExtraColumns,
          }));
        }
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(
                  err.message,
                  "fetchDucMaster",
                  "Edit DucMasterList"
                )
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  };

  function getClientData() {
    axiosWithToken
      .get(BASE_URL + `clients?_where=(status,eq,1)`)
      .then((res) => {
        let newData = res.data;
        setClientList(newData);
      })
      .catch((err) => {
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(err.message, "getClientData", "Edit DucMasterList")
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  }

  function getInstrumentstData() {
    axiosWithToken
      .get(BASE_URL + `instruments?_where=(status,eq,1)`)
      .then((res) => {
        let newData = res.data;
        setInstrumentstList(newData);
      })
      .catch((err) => {
        console.log("instrument_master data fetching error: ", err);
        if (err.message !== "request_aborted") {
          toast.error(
            <h6
              onClick={() =>
                openErrorModal(
                  err.message,
                  "getInstrumentsList",
                  "Edit DucMasterList"
                )
              }
            >
              <u>Error: {err.message}</u>
            </h6>
          );
        }
      });
  }

  var refresh = () => {
    window.location.reload(false);
  };

  useEffect(() => {
    if (params.id) fetchDucMaster();
  }, [params.id]);
  useEffect(() => {
    getClientData();
    getInstrumentstData();
  }, []);

  useEffect(() => {
    const fetchSetting = async () => {
      try {
        const AllSettings = await allSettings();
        let additionalColumns = AllSettings.find(
          (ele) => ele.keyName === "srfInstrumentsAdditionalColumns"
        );
        if (additionalColumns) {
          let splitColums = additionalColumns?.value?.split(",");
          setAdditionalColumns(splitColums);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchSetting();
  }, []);

  return (
    <EditWrap>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h6" component="h6" style={{ float: "left" }}>
              Create/Update DUC Master
            </Typography>
            <Tooltip title="User Guide" placement="top-start">
              <Button
                onClick={() => {
                  createducMasterGuides.drive();
                }}
              >
                <HelpIcon />
              </Button>
            </Tooltip>
          </div>
          <Grid container spacing={2} style={{ marginBottom: "10px" }}>
            <Grid item xs={6} sm={6} md={4} lg={3}>
              <Autocomplete
                // disabled={params.id}
                size="small"
                id="ducMaster_create_companyname"
                options={clientList}
                getOptionLabel={(option) =>
                  `${option.id},${option.companyName}`
                }
                value={
                  clientList.find((lab) => lab.id === inputStates.clientId) ||
                  null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Client *" />
                )}
                onChange={(event, value) => {
                  if (value) {
                    updateInputObject("clientId", value.id);
                  } else {
                    updateInputObject("clientId", "");
                  }
                }}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={4} lg={3}>
              <Autocomplete
                //   disabled={params.invoiceId}
                size="small"
                id="ducMaster_create_instrumentname"
                options={instrumentstList}
                getOptionLabel={(option) =>
                  `${option.id}, ${option.instrumentName}`
                }
                value={
                  instrumentstList.find(
                    (lab) => lab.id === inputStates.instrumentId
                  ) || null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Instrumentst Name *" />
                )}
                onChange={(event, value) => {
                  if (value) {
                    updateInputObject("instrumentId", value.id);
                    setDisciplineId(value.disciplineId);
                  } else {
                    updateInputObject("instrumentId", "");
                    setDisciplineId(null);
                  }
                }}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={4} lg={3}>
              <TextField
                id="ducMaster_create_ducid"
                label="DUCID *"
                size="small"
                value={inputStates.DUCID || ""}
                fullWidth
                inputProps={{
                  autoComplete: 'off',
                }}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("DUCID", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={4} lg={3}>
              <TextField
                id="ducMaster_create_serialno"
                label="Serial Number *"
                size="small"
                value={inputStates.serialNumber || ""}
                fullWidth
                inputProps={{
                  autoComplete: 'off',
                }}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("serialNumber", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={4} lg={3}>
              <TextField
                id="ducMaster_create_make"
                label="Make"
                size="small"
                fullWidth
                inputProps={{
                  autoComplete: 'off',
                }}
                variant="outlined"
                value={inputStates.make || ""}
                onChange={(e) => {
                  updateInputObject("make", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={4} lg={3}>
              <TextField
                id="ducMaster_create_model"
                label="Model"
                size="small"
                inputProps={{
                  autoComplete: 'off',
                }}
                value={inputStates.model || ""}
                fullWidth
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("model", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={4} lg={3}>
              <TextField
                id="ducMaster_create_locinst"
                label="Location Of Instrument"
                size="small"
                value={inputStates.locationOfInstrument || ""}
                fullWidth
                inputProps={{
                  autoComplete: 'off',
                }}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("locationOfInstrument", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={4} lg={3}>
              <TextField
                id="ducMaster_create_dept"
                label="Department"
                size="small"
                value={inputStates.department || ""}
                fullWidth
                inputProps={{
                  autoComplete: 'off',
                }}
                variant="outlined"
                onChange={(e) => {
                  updateInputObject("department", e.target.value);
                }}
              />
            </Grid>

            <Grid item xs={6} sm={6} md={4} lg={3}>
              <Autocomplete
                //   disabled={params.invoiceId}
                size="small"
                id="ducMaster_create_calfreq"
                options={calArray}
                getOptionLabel={(option) => option.label}
                value={
                  calArray.find(
                    (lab) => lab.label === inputStates.calFrequency
                  ) || null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Cal Frequency" />
                )}
                onChange={(event, value) => {
                  if (value) {
                    updateInputObject("calFrequency", value.label);
                  } else {
                    updateInputObject("calFrequency", "");
                  }
                }}
              />
            </Grid>

            <Grid item spacing={3} xs={12} sm={12} md={8} lg={8}>
              <div
                style={{
                  display: "flex",
                  columnGap: "50px",
                  rowGap: "20px",
                  alignItems: "center",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                {modalConfigs.map((config, index) => (
                  <MultiValueInputModal
                    key={index}
                    buttonLabel={config.buttonLabel}
                    transformedStringLabel={config.transformedStringLabel}
                    inputStates={inputStates}
                    updateInputObject={updateInputObject}
                    disciplineId={disciplineId}
                  />
                ))}
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {additionalColumns?.map((column, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                <TextField
                  size="small"
                  label={`${column}`}
                  value={extraColumns[column] || ""}
                  onChange={(e) => handleInputChange(column, e.target.value)}
                  fullWidth
                />
              </Grid>
            ))}
          </Grid>
          <Toolbar
            style={{
              padding: "0px",
              overflow: "auto",
              display: "flex",
              justifyContent: "flex-end",
              gap: "20px",
            }}
          >
            <Button
              variant="contained"
              style={{ backgroundColor: "grey" }}
              size="small"
              onClick={() => {
                if (window.confirm("Are you sure you want to cancel?")) {
                  window.history.back();
                }
              }}
            >
              Cancel
            </Button>
            <Button
              id="ducMaster_create_save"
              variant="contained"
              size="small"
              sx={{ m: 0 }}
              
    disabled={!(editAccess?.includes(1) || editAccess?.includes(0))}
              onClick={() => {
                if (params.id == undefined) {
                  if (!inputStates?.clientId) {
                    toast.error('Please select a Client');
                    return;
                  }
                  if (!inputStates?.instrumentId) {
                    toast.error('Please select a instrument');
                    return;
                  }
                  if (!(inputStates?.DUCID || inputStates?.serialNumber)) {
                    toast.error('DUCID or Serial Number is required');
                    return;
                  }
                }
                handleSubmit();
              }}
            >
              {params.id ? "Update" : "Save"}
            </Button>
          </Toolbar>
       
      {errormodalIsOpen && (
        <ErrorModal
          errormodalIsOpen={errormodalIsOpen}
          closeErrorModal={closeErrorModal}
          errorMessage={errorMessage}
        />
      )}
    </EditWrap>
  );
};

export default EditDucMaster;
